import styles from './CBComponent.module.css'
import { DSCBComponent, DSTpl } from 'shared-definitions/types'
import ComponentTitle from 'shared-components/ui/ComponentTitle'
import { CBAdsControlContext } from 'shared-components/contexts/CBAdsControlContext'
import NC from 'nc/NC'
import { DSNCSG } from 'types.sg.ds'

interface CBComponentProps {
  data: DSCBComponent<DSNCSG>
  tpl: DSTpl
}

const CBComponent: React.VFC<CBComponentProps> = ({ data, tpl }) => {
  const { voidQuantity } = CBAdsControlContext.useContainer()

  if (data.component.length === voidQuantity) {
    return null
  }

  return (
    <div className={styles.container}>
      <ComponentTitle header={data.header} />
      {data.component.map((item, index) => (
        <NC key={index} data={item} tpl={tpl} index={index} />
      ))}
    </div>
  )
}

export default CBComponent
