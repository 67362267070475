/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from 'react'
import { DSTileIcon } from 'shared-definitions/types'
import Book from 'shared-svg/Book.svg'
import Earbuds from 'shared-svg/Earbuds.svg'
import Headphone from 'shared-svg/Headphone.svg'
import Microphone from 'shared-svg/Microphone.svg'
import Speakers from 'shared-svg/Speakers.svg'
import Star from 'shared-svg/Star.svg'

const componentsMap: Record<DSTileIcon, React.VFC> = {
  headphones: Headphone,
  speakers: Speakers,
  microphone: Microphone,
  book: Book,
  star: Star,
  earbuds: Earbuds,
}

interface TileIconProps extends React.SVGAttributes<SVGElement> {
  icon: DSTileIcon
}

const TileIcon: React.VFC<TileIconProps> = ({ icon, ...props }) => {
  const Component = componentsMap[icon]
  if (Component) {
    return <Component {...props} />
  }
  return <Speakers {...props} />
}

export default TileIcon
