import { MapDiscriminatedComponent } from 'shared-definitions/types'
import { componentMapper } from 'shared-code/component'
import CBMissed from 'home/CBMissed'
import CBFeatured from 'home/CBFeatured'
import CBComponent from 'home/CBComponent'
import { DSContentBlockSG } from 'types.sg.ds'
import { CBAdsControlContext } from 'shared-components/contexts/CBAdsControlContext'
import CBTiles from 'home/CBTiles'
import CBColoredTiles from 'home/CBColoredTiles'
import CBCoupledPosts from 'home/CBCoupledPosts'

const map: MapDiscriminatedComponent<DSContentBlockSG, 'resource'> = {
  'cb-featured': CBFeatured,
  'cb-missed': CBMissed,
  'cb-component': args => (
    <CBAdsControlContext.Provider>
      <CBComponent {...args} />
    </CBAdsControlContext.Provider>
  ),
  'cb-tiles': CBTiles,
  'cb-colored-tiles': CBColoredTiles,
  'cb-coupled-posts': CBCoupledPosts,
}

const ContentBlock = componentMapper(map)

export default ContentBlock
