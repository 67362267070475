import { DSCBColoredTiles, DSTpl } from 'shared-definitions/types'
import ComponentTitle from 'shared-components/ui/ComponentTitle'
import ColoredBox from 'shared-components/ui/ColoredBox'
import styles from './CBColoredTiles.module.css'
import { DSNCSG } from 'types.sg.ds'
import NC from 'nc/NC'

interface CBColoredTilesProps {
  data: DSCBColoredTiles<DSNCSG>
  tpl: DSTpl
}

const CBColoredTiles: React.VFC<CBColoredTilesProps> = ({ data, tpl }) => (
  <div className={styles.container}>
    <ComponentTitle header={data} className={styles.title} />
    <div className={styles.grid}>
      {data.tiles.map((item, index) => (
        <ColoredBox key={index} box={item} />
      ))}
    </div>
    {data.blocks.map((item, index) => (
      <NC key={index} data={item} tpl={tpl} index={index} />
    ))}
  </div>
)

export default CBColoredTiles
