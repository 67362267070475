import clsx from 'clsx'
import React from 'react'

import PostHeroMain from 'shared-components/posts/PostsHeroMain'
import PostsHeroSide from 'shared-components/posts/PostsHeroSide'

import type { DSCBFeatured } from 'shared-definitions/types'

import styles from './CBFeatured.module.css'

interface CBFeaturedProps {
  data: DSCBFeatured
}

const CBFeatured: React.FC<CBFeaturedProps> = ({ data }) => {
  const [main, ...sides] = data.items

  return (
    <div className={styles.container}>
      <div className={styles.postHero}>
        {main ? <PostHeroMain post={main} className={styles.m} /> : null}

        {sides.slice(0, 4).map((item, index) => (
          <PostsHeroSide
            key={index}
            post={item}
            className={clsx({
              [styles.tl]: index === 0,
              [styles.bl]: index === 2,
              [styles.tr]: index === 1,
              [styles.br]: index === 3,
            })}
          />
        ))}
      </div>
    </div>
  )
}

export default CBFeatured
