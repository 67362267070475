import ContentBlock from 'home/ContentBlock'
import TopBannerInContent from 'shared-components/ad/TopBannerInContent'
import { useTaglessAd } from 'shared-components/hooks/use-tagless-ad'
import styleUtils from 'shared-components/styles/util.module.css'
import Disclaimer from 'shared-components/ui/Disclaimer'
import { DSHomePage } from 'shared-definitions/types'
import { DSContentBlockSG } from 'types.sg.ds'
import styles from './HomeTemplate.module.css'

type LayoutItem =
  | { variant: 'ad'; type: PlaywireTaggedType }
  | { variant: 'grid'; items: DSContentBlockSG[] }
  | { variant: 'splitter' }

function mixComponentBlocks(items: readonly DSContentBlockSG[]): LayoutItem[] {
  const layout: LayoutItem[] = [
    {
      variant: 'grid',
      items: items.slice(0, 1),
    },
    {
      variant: 'splitter',
    },
    {
      variant: 'grid',
      items: items.slice(1, 5),
    },
    {
      variant: 'ad',
      type: 'leaderboard_btf',
    },
  ]

  const restBlocks = items.slice(5)
  for (let i = 0; i < restBlocks.length; i += 4) {
    const subItems = restBlocks.slice(i, i + 4)
    layout.push({
      variant: 'grid',
      items: subItems,
    })

    // do not place ad after lists with articles
    if (restBlocks.length > i + 3) {
      layout.push({
        variant: 'ad',
        type: 'leaderboard_btf',
      })
    }
  }
  return layout
}

interface HomeTemplateProps {
  page: DSHomePage<DSContentBlockSG>
}

const HomeTemplate: React.VFC<HomeTemplateProps> = ({ page }) => {
  useTaglessAd(['bottom_rail'])

  const layout = mixComponentBlocks(page.blocks)

  return (
    <div className={styleUtils.wrapper}>
      <Disclaimer />
      {layout.map((part, partIndex) => {
        if (part.variant === 'ad') {
          return (
            <div key={partIndex} className={styles.leaderboard}>
              <TopBannerInContent className={styleUtils.mobileFull} />
            </div>
          )
        }
        if (part.variant === 'splitter') {
          return <div className={styles.leaderboard} key={partIndex} />
        }

        return (
          <div className={styles.grid} key={partIndex}>
            {part.items.map((item, index) => (
              <ContentBlock key={index} index={index} data={item} tpl="home" />
            ))}
          </div>
        )
      })}
    </div>
  )
}

export default HomeTemplate
