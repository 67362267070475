import { DSCBTiles } from 'shared-definitions/types'
import ComponentTitle from 'shared-components/ui/ComponentTitle'
import styles from './CBTiles.module.css'
import Tile from 'shared-components/ui/Tile'

interface CBTilesProps {
  data: DSCBTiles
}

const CBTiles: React.VFC<CBTilesProps> = ({ data }) => (
  <div className={styles.container}>
    <ComponentTitle header={data} />
    <div className={styles.grid}>
      {data.tiles.map((item, index) => (
        <Tile tile={item} key={index} />
      ))}
    </div>
  </div>
)

export default CBTiles
