import styles from './ColoredBox.module.css'
import ArrowRightBird from 'shared-svg/ArrowRightBird.svg'
import clsx from 'clsx'
import { BasicStyledComponent, DSColoredBox } from 'shared-definitions/types'
import Linked from 'shared-components/service/Linked'
import { CSSProperties } from 'react'
import { extractColorDigits } from 'shared-code/conversion'

interface ColoredBoxProps extends BasicStyledComponent {
  box: DSColoredBox
}

const ColoredBox: React.VFC<ColoredBoxProps> = ({ className, box }) => (
  <Linked
    {...box.pLink}
    className={clsx(styles.container, className)}
    style={{ '--color': extractColorDigits(box.color).join(',') } as CSSProperties}
  >
    <div className={styles.title}>
      <span className={styles.titleText}>{box.title}</span>
      <ArrowRightBird className={styles.arrowIcon} />
    </div>
  </Linked>
)

export default ColoredBox
